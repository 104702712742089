<template>
  <div class="container-fluid">
    <Header />
    <div class="mx-auto mt-1 content-outer-container">
      <div class="header">
        <div class="headerLeft">
          <div class="headerIcon">
            <img
              :src="require('@/assets/images/icon_notification.svg')"
              alt=""
              width="60"
              height="60"
            />
          </div>
          <div class="headerText">
            <div class="mainText">{{ lbl['main-menu-fgf'] }}</div>
            <div class="subText">
              {{ lbl['fgf-headersubtext'] }}
            </div>
          </div>
        </div>
        <div v-if="mode === 'view'" class="editBtn">
          <a-tooltip placement="bottom">
            <template v-if="isActive" #title>{{
              lbl['fgf-editcampaigntip']
            }}</template>
            <button
              id="friendgetfriends_main_toedit_button"
              @click="toEdit"
              :class="[isActive ? 'disabled' : '']"
            >
              <img
                :src="
                  require(`@/assets/images/${
                    isActive ? 'editdisabled' : 'edit'
                  }.svg`)
                "
                alt=""
                width="24"
                height="24"
              />{{ lbl['fgf-editcampaign'] }}
            </button>
          </a-tooltip>
        </div>
      </div>
      <div class="mainContainer">
        <MainForm
          v-if="mode === 'view'"
          :mode="mode"
          :data="data"
          :isActive="isActive"
          :maxPoint="maxPoint"
          :imgPreview="imgPreview"
          :statsBoxData="statsBoxData"
          :changeStatusFGF="changeStatusFGF"
          :row-key="rowkey"
        />
        <div v-else-if="mode === 'create'">
          <div class="imgContainer">
            <img src="@/assets/images/emptyFGF.png" alt="" />
          </div>
          <div class="textTitle">
            {{ lbl['fgf-createnewfgf'] }}
          </div>
          <div class="btnContainer">
            <button
              id="friendgetfriends_main_gotopagecreateupdate_button"
              @click="gotoPage('FriendGetFriendsCU', 'create', 'create')"
              :disabled="isDisable"
              :class="{ 'disable-btn': isDisable }"
              class="okBtn"
            >
              <img
                :src="require('@/assets/images/add.svg')"
                alt=""
                width="24"
                height="24"
              />{{ lbl['fgf-createnew'] }}
            </button>
          </div>
        </div>
        <div v-else>
          <a-skeleton active />
        </div>
      </div>
    </div>
    <div class="history" v-if="historyList.length > 0">
      <div class="hText">{{ lbl['main-menu-history-fgf'] }}</div>
      <button
        id="friendgetfriends_main_gotopagehistory_button"
        @click="gotoPage('FriendGetFriendsHistory', 'history', 'history')"
        class="nextBtn"
      >
        <img
          :src="require('@/assets/images/arrow-forward.svg')"
          alt=""
          width="24"
          height="24"
        />
      </button>
    </div>
    <Modal
      :title="lbl['fgf-confirmeditcampaigntitle']"
      :details="lbl['fgf-confirmeditcampaignsubtitle']"
      :backto="lbl['fgf-buttoncancel']"
      :isOpenModal="isModaltoEdit"
      :onActionModal="toEditModal"
    />
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import MainForm from './component/MainForm'
import BzbsUserFGF from '@/core/FriendgetFriends/service/BzbsFriendgetFriends'
import moment from 'moment'
import Modal from '../FriendGetFriends/component/FGFModal'
export default {
  name: 'FriendGetFriends',
  components: {
    Header,
    MainForm,
    Modal,
  },
  mixins: [Mixin],
  data() {
    return {
      mode: 'load',
      data: {},
      isActive: false,
      maxPoint: '',
      imgPreview: '',
      rowkey: '',
      isModaltoEdit: false,
      statsBoxData: [
        {
          icon: `statsbox2`,
          title: 'newuser',
          value: '500',
          unit: 'account',
          bg: '#2196f3',
          key: 'list-newuser',
        },
        {
          icon: `statsbox3`,
          title: 'pointspendInviter',
          value: '1630',
          unit: 'point',
          bg: '#ff9800',
          key: 'list-inviter',
        },
        {
          icon: `statsbox4`,
          title: 'pointspendInvited',
          value: '1630',
          unit: 'point',
          bg: '#ff9800',
          key: 'list-invited-people',
        },
      ],
      historyList: [],
      loginType: null,
      appId: null,
      liffId: null,
      liffLanding: null,
    }
  },
  computed: {
    isDisable() {
      if (this.loginType.toLowerCase() == 'line') {
        if (this.appId && (this.liffLanding || this.liffId)) {
          return false
        } else {
          return true
        }
      } else {
        if (this.appId) {
          return false
        } else {
          return true
        }
      }
    },
  },
  created() {
    this.handleFooter(true)
    Account.bzbsAnalyticTracking(
      'get_friend_page',
      'get_friend',
      'view_get_friend',
      'on view',
    )
    this.getCRMPlusProfile().then(result => {
      this.loginType = result.LoginType
      this.appId = result.AppId
      this.liffId = result.LiffId
      this.liffLanding = result.LiffLanding
      this.getActiveFGF()
      this.getHistoryFGF()
    })
  },
  methods: {
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            resolve(res.data)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    gotoPage(page, mode, id) {
      switch (mode) {
        case 'create':
          Account.bzbsAnalyticTracking(
            'get_friend_page',
            'get_friend',
            'click_get_friend_create',
            'on click',
          )
          break

        case 'edit':
          Account.bzbsAnalyticTracking(
            'get_friend_page',
            'get_friend',
            'click_get_friend_edit',
            'on click',
          )
          break
        case 'history':
          Account.bzbsAnalyticTracking(
            'get_friend_page',
            'get_friend',
            'click_get_friend_history',
            'on click',
          )
          break
      }
      this.$router.push({
        name: page,
        params: { mode: mode, id: id },
      })
    },
    toEdit() {
      if (!this.isActive) {
        this.isModaltoEdit = true
        Account.bzbsAnalyticTracking(
          'get_friend_page',
          'get_friend',
          'click_get_friend_edit',
          'on click',
        )
      }
    },
    toEditModal(state, confirm) {
      this.isModaltoEdit = state
      if (confirm) this.gotoPage('FriendGetFriendsCU', 'edit', this.rowkey)
    },
    changeStatusFGF() {
      this.handleLoading(true)
      let state = 'toActive'
      if (this.isActive) {
        state = 'toInActive'
      }
      BzbsUserFGF.changeStatus(this.rowkey, state)
        .then(res => {
          console.log(res)
          this.getActiveFGF()
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.handleLoading(false)
        })
    },
    getActiveFGF() {
      this.handleLoading(true)
      BzbsUserFGF.getActiveFGF()
        .then(res => {
          console.log('getActiveFGF', res.data)
          let item = res.data
          this.rowkey = item.RowKey
          this.isActive = item.Status === 'Create' || item.Status === 'Publish'
          this.imgPreview = item.ImageUrl
          this.maxPoint = item.MaximumAccount > 0 ? 'Limit' : 'Unlimit'
          this.data = {
            name: item.Name,
            description: item.Description,
            referral_points: item.ReferralPoints,
            user_points: item.UserPoints,
            max_account: item.MaximumAccount,
            joinDays: item.MinimumFriendGetFriendJoinDays,
            joinDaysState: item.MinimumFriendGetFriendJoinDays > 0,
            validateEarnAndRedeem: item.ValidateFriendGetFriendEarnAndRedeem,
            date: [
              moment(item.StartDate * 1000),
              moment(item.ExpireDate * 1000),
            ],
            image: item.ImageUrl,
            invite_message: item.InviteMessage,
          }
          this.statsBoxData.forEach(el => {
            if (el.title === 'join') el.value = item.TotalInviteCount
            if (el.title === 'newuser') el.value = item.TotalInviteCount
            if (el.title === 'pointspendInviter')
              el.value = item.TotalReferralPoints
            if (el.title === 'pointspendInvited')
              el.value = item.TotalUserPoints
          })
          if (res.data && item.ExpireDate * 1000 > Date.parse(new Date())) {
            this.mode = 'view'
          } else {
            this.mode = 'create'
          }
        })
        .catch(err => {
          this.mode = 'create'
          console.log(err)
        })
        .finally(() => this.handleLoading(false))
    },
    getHistoryFGF() {
      BzbsUserFGF.getHistoryFGF()
        .then(res => {
          this.historyList = res.data.filter(
            i => !i.Active || i.Status == 'Expired',
          )
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  color: #212121;
  border-bottom: 2px solid #f5f5f5;
  .headerLeft {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .headerText {
    .mainText {
      font-size: 34px;
      font-weight: normal;
    }
    .subText {
      font-size: 14px;
      font-weight: 500;
      color: #757575;
    }
  }
  .editBtn {
    button {
      font-size: 16px;
      font-weight: 500;
      width: 199px;
      height: 56px;
      border-radius: 4px;
      background-color: white;
      border: solid 1px #ff9800;
      color: #ff9800;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-right: 8px;
      }
      &.disabled {
        border: solid 1px #ccc;
        color: #ccc;
        svg {
          path {
            fill: #ccc;
          }
        }
      }
    }
  }
}
.mainContainer {
  padding: 30px;
  .imgContainer {
    display: flex;
    justify-content: center;
    img {
      width: 542.9px;
      height: 379px;
    }
  }
  .textTitle {
    font-size: 24px;
    color: #424242;
    max-width: 713px;
    margin: 0 auto;
    text-align: center;
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    .disable-btn {
      opacity: 0.5;
    }
    .okBtn {
      img {
        margin-top: -4px;
        margin-right: 8px;
      }
      width: 215px;
      height: 56px;
      border-radius: 4px;
      border: solid 1px #ff9800;
      background-color: #ff9800;
      color: white;
    }
  }
}
.history {
  max-width: 1400px;
  margin: 30px auto;
  background-color: white;
  border-radius: 5px;
  font-size: 34px;
  color: #212121;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  .nextBtn {
    width: 72px;
    height: 56px;
    border-radius: 4px;
    background-color: white;
    border: solid 1px #ff9800;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.container-fluid {
  background-size: cover !important;
  background-attachment: fixed;
  background-position: center;
}
</style>
